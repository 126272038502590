import useGetPanelPrimaryAndTestData from './useGetPanelPrimaryAndTestData.hooks'

const useCorePanelTestData = (corePanelCodes?: string[]) => {
  // contentful model allows for multiple entries, but we only want the first for now
  const primaryCorePanelCode = corePanelCodes?.[0] || ''
  const {primaryPanelData: corePanelData, ...rest} = useGetPanelPrimaryAndTestData(primaryCorePanelCode)
  return {corePanelData, ...rest}
}

export default useCorePanelTestData
