import * as React from 'react'
import Skeleton from 'react-loading-skeleton'

import {IPanelCardsItemFields} from '@invitae/contentful-types'
import {BaseNucleobaseComponent, NucleobaseComponent, useAnalyticsEvents} from '@invitae/nucleobase'

import useCorePanelTestData from 'hooks/useCorePanelTestData'
import useGetPanelPrimaryAndTestData from 'hooks/useGetPanelPrimaryAndTestData.hooks'

import PanelCardsItem from './index'

import styles from './PanelCardItem.module.scss'

const SKELETON_LINE_COUNT = 3
const SKELETON_LINE_HEIGHT_PX = 30

const PanelCardsItemWrapper: NucleobaseComponent<IPanelCardsItemFields> = ({
  fields: {corePanelCodes, panelCode: mainCode, link, showGeneCount},
}) => {
  const {primaryPanelData, optionalTestList, isError} = useGetPanelPrimaryAndTestData(mainCode)
  const {corePanelData} = useCorePanelTestData(corePanelCodes)

  const analyticsProps = useAnalyticsEvents({analyticsEvents: link?.fields.analyticsEvents})
  // we want it to fail silently and not render an error to the user
  if (isError) return null
  if (!primaryPanelData)
    return (
      <div className={styles.skeletonWrapper} data-testid="panel-cards-loading-skeleton" id="panelCardsLoadingSkeleton">
        <Skeleton count={SKELETON_LINE_COUNT} height={SKELETON_LINE_HEIGHT_PX} />
      </div>
    )

  return (
    <PanelCardsItem
      corePanelData={corePanelData}
      optionalTestsData={optionalTestList}
      primaryPanelData={primaryPanelData}
      showGeneCount={showGeneCount}
    >
      <BaseNucleobaseComponent
        classes={{
          root: styles.titleRoot,
        }}
        rootElementProps={analyticsProps}
        source={link?.fields.linkScheme ?? ''}
      >
        <p className={styles.cardTitle} data-testid="panel-cards-item-title-link">
          {corePanelData?.title ? (
            <>
              {corePanelData.title} with {primaryPanelData?.title}
            </>
          ) : (
            <>{primaryPanelData?.title}</>
          )}
        </p>
      </BaseNucleobaseComponent>
    </PanelCardsItem>
  )
}

export default PanelCardsItemWrapper
