import * as React from 'react'

import PanelDetailsContext from 'components/PanelDetail/PanelDetailContext'
import {IPanelData, IPanelGene} from 'hooks/types'

import GeneListTooltipItem from './GeneListTooltipItem'

import styles from './GeneListTooltip.module.scss'

interface IGeneListTooltipProps {
  corePanelData?: IPanelData
  primaryPanelData: IPanelData
  optionalTestList?: IPanelData[]
  selectedPanels: string[]
}

const GeneListTooltip = ({
  corePanelData,
  primaryPanelData,
  optionalTestList,
  selectedPanels,
}: IGeneListTooltipProps) => {
  const {unselectedGenes} = React.useContext(PanelDetailsContext)

  const isPanelSelected = React.useCallback(
    (panelId: string) => {
      return selectedPanels.includes(panelId)
    },
    [selectedPanels],
  )

  const isGeneSelected = React.useCallback(
    (gene: IPanelGene) => {
      if (unselectedGenes) return !unselectedGenes.some(item => item.name === gene.name)

      // return value that gene is selected when unselected is not provided
      // handles issues on other pages
      return true
    },
    [unselectedGenes],
  )

  return (
    <div className={styles.root} data-testid="gene-list-tooltip" id="geneListTooltipId">
      <div className={styles.addonList}>
        {corePanelData && (
          <GeneListTooltipItem
            isGeneSelected={() => true}
            isPanelSelected={() => true}
            item={corePanelData}
            key={corePanelData.title}
            title="Core panels"
          />
        )}

        <GeneListTooltipItem
          isGeneSelected={isGeneSelected}
          isPanelSelected={isPanelSelected}
          item={primaryPanelData}
          title="Primary Panel"
        />

        {optionalTestList?.map(item => (
          <GeneListTooltipItem
            isGeneSelected={isGeneSelected}
            isPanelSelected={isPanelSelected}
            item={item}
            key={item.title}
          />
        ))}
      </div>
    </div>
  )
}

export default GeneListTooltip
